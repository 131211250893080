import React from 'react';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import AppContext from '../AppContext';
import ResultsSummary from '../ResultsSummary';
import { draftJsToTipTap, parseToHtml } from '../../utils/parseToHTML';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid #d1d1d1',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);


export default function ResultsSections() {

  function convertContent(content) {
    const contentState = draftJsToTipTap(content);
    let html = parseToHtml(contentState);
    return html;
  }

  return (
    <AppContext.Consumer>
      {(context) =>
        <React.Fragment>
          {(context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null) &&
          <React.Fragment>
            {context.state.eligibilityResult[0].recruiterOutput.sections.length > 0
              ? <React.Fragment>
                <div className="result-section-container">
                  {context.state.eligibilityResult[0].recruiterOutput.sections.map((section,index) => (
                    <div >
                      <ExpansionPanel defaultExpanded={false} key={index}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="results-expand-more"/>}>
                          <h6 className="results-sections-title pt-1">{section.title.trim().charAt(0).toUpperCase() + section.title.trim().slice(1).toLowerCase()}</h6>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div className="results-description">
                            <div className="content" dangerouslySetInnerHTML={{__html: convertContent(section.content)}}></div>
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  ))}

                  {/* The eligible table. */}
                  <div className={'vc__hide-app-summary-pdf'}>
                    <ExpansionPanel defaultExpanded={true}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="results-expand-more"/>}>
                        <h6 className="results-sections-title pt-1">Application summary</h6>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div className="results-description">
                        <p className="vc__application-summary-note"><b>Note:</b> The application summary will not appear in the exported PDF file. </p>
                          <ResultsSummary
                            isEligible = {true}/>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </div>
              </React.Fragment>

              : <div className={'vc__hide-app-summary-pdf' + (context.state.eligibilityResult[0].recruiterOutput.sections.length > 0 ? '' : ' result-section-container')}>
                  <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="results-expand-more"/>}>
                      <h6 className="results-sections-title pt-1">Application summary</h6>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="results-description">
                        <p className="vc__application-summary-note"><b>Note:</b> The application summary will not appear in the exported PDF file. </p>
                        <ResultsSummary
                          isEligible = {true}/>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
            }
          </React.Fragment>
          }
        </React.Fragment>
      }
    </AppContext.Consumer>
  );
}

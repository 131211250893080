import React, { useEffect, useRef, useState } from 'react';
import { authApi } from '../../api';
import { ChevronLeft, ChevronRight, ErrorOutlineOutlined } from '@material-ui/icons';
import { Carousel, CarouselItem } from 'reactstrap';
import { Transition } from 'react-transition-group';
import { parseToHtml, draftJsToTipTap } from '../../utils/parseToHTML';

const api = authApi();

const ShowAnnouncement = ({selectedDestinationCountry}) => {
  const [announcementType, setAnnouncementType] = useState(1);
  const [announcementItems, setAnnouncementItems] = useState([]);
  const [announcementsToShow, setAnnouncementsToShow] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  // const [animating, setAnimating] = useState(false);
  const [visible, setVisible] = useState(true);
  const [globalDismissed, setGlobalDismissed] = useState(false);
  const [dismissedCountryIds, setDismissedCountryIds] = useState([]);
  const announcementBar = useRef(null);
  // const [transitionStyles, setTransitionStyles] = useState({
  //   entering: { opacity: 1 },
  //   entered:  { opacity: 1 },
  //   exiting: { marginTop: '-90px', opacity: 0 },
  //   exited: { marginTop:  '-90px', opacity: 0},
  // });

  const carouselPolyfill = () => {
    (function () {
      function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
       }

      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
    })();
  };

  useEffect(() => {
    carouselPolyfill();
    fetchAnnouncement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = [];
    if (selectedDestinationCountry) {
      announcementItems.forEach(el => {
        if (el.selectedAnnouncementCountries) {
          // Check if the country ids in the list match the selected destination country.
          if (el.selectedAnnouncementCountries.find(announcementCountry => announcementCountry.country.id === selectedDestinationCountry.id) &&
            !dismissedCountryIds.includes(selectedDestinationCountry.id)) {
              result.push(el);
            }
        }
      });
    } if (result.length <= 0) {
      announcementItems.forEach(el => {
        if (el.isGlobal && !globalDismissed) {
          result.push(el);
        }
      });
    }

    if (result.length > 0) {
      setAnnouncementType(result[0].type);
      setVisible(true);
    }

    setAnnouncementsToShow(result);
    setActiveIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationCountry, window.location.hash]);

  // Hide the entire announcement bar
  const onDismiss = (e) => {
    // setTransitionStyles({
    //   entering: { opacity: 1 },
    //   entered:  { opacity: 1 },
    //   exiting: { marginTop: -announcementBar.current.clientHeight, opacity: 1 },
    //   exited: { marginTop: -announcementBar.current.clientHeight, opacity: 0 },
    // });
    setVisible(false);
    if (!selectedDestinationCountry) {
      setGlobalDismissed(true);
    } else {
      dismissedCountryIds.push(selectedDestinationCountry.id);
      setDismissedCountryIds(dismissedCountryIds);
    }
  };

  // Retrieve announcements stored in the database via the client backend.
  const fetchAnnouncement = async () => {
    const announcementResponse = await api.get('/announcements');

    if (announcementResponse && announcementResponse.data[0]) {
      const result = [];
      announcementResponse.data.forEach(el => {
        //if payload is JSON format(ie. not text, convert to html format)
        if(jsonChecker(el.text)){
          let resultAnnouncements = draftJsToTipTap(el.text);
          let html = parseToHtml(resultAnnouncements);
          el.text = html;
        }
        if (el.isGlobal) {
            result.push(el);
        }
      });

      setAnnouncementItems(announcementResponse.data);
      if (result.length > 0) {
        setAnnouncementsToShow(result);
        setAnnouncementType(result[0].type);
      }
    }
  };

  //Check if input is a valid JSON file
  const jsonChecker = (item) => {
    item = typeof item !== 'string'
    ? JSON.stringify(item)
    : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === 'object' && item !== null) {
        return true;
    }

    return false;
  };

  const next = () => {
    // if (animating) return;
    const nextIndex = activeIndex === announcementsToShow.length - 1 ? 0 : activeIndex + 1;
    if (nextIndex < announcementsToShow.length) {
      setAnnouncementType(announcementsToShow[nextIndex].type);
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(0);
      setAnnouncementType(announcementsToShow[activeIndex].type);
    }
  };

  const previous = () => {
    // if (animating) return;
    const nextIndex = activeIndex === 0 ? announcementsToShow.length - 1 : activeIndex - 1;
    if (nextIndex < announcementsToShow.length) {
      setAnnouncementType(announcementsToShow[nextIndex].type);
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(0);
      setAnnouncementType(announcementsToShow[activeIndex].type);
    }
  };

  // const defaultStyle = {
  //   transition: `all ${300}ms ease-in-out`,
  //   opacity: 0,
  // }

  const getAnnouncementTypeClass = () => {
    switch (announcementType) {
      case 2:
        return ' warning';
      case 3:
        return ' alert';
      default:
        return ' information';
    }
  };

  const slides = announcementsToShow.map((item) => {
    return (
      <CarouselItem
        // onExiting={() => setAnimating(true)}
        // onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div className="vc__announcement-item">
          <ErrorOutlineOutlined className="vc__announcement-icon mr-2" />
          <div className="vc__announcement-text">
            <b className="vc__announcement-title">
              {item.title.concat(' ')}
            </b>
            {/* show HTML code to display format and clickable links */}
            <div dangerouslySetInnerHTML={{ __html:item.text.concat(' ') }} />
          </div>
        </div>
      </CarouselItem>
    );
  });

  // only show this banner if there is an announcement being retrieved.
  if (announcementsToShow.length <= 0) {
    return null;
  }

  return (
    <Transition in={visible} timeout={0} unmountOnExit>
    {state => (
      <div style={{
        // ...defaultStyle,
        // ...transitionStyles[state]
      }} className="">
        <div className={`vc__announcement ${getAnnouncementTypeClass()}`} ref={ announcementBar }>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
          >
            { slides  }
            <div className={`carousel-control-prev ${announcementsToShow.length > 1 ? '' : 'd-none'}`} onClick={previous}>
              <ChevronLeft fontSize="inherit"/>
            </div>
            <div className={`carousel-control-next ${announcementsToShow.length > 1 ? '' : 'd-none'}`} onClick={next}>
              <ChevronRight fontSize="inherit"/>
            </div>
          </Carousel>

          <button type="button" className="close" aria-label="Close" onClick={(e) => onDismiss(e)}><span aria-hidden="true">×</span></button>
        </div>
       </div>
     )}
   </Transition>
  );
};

export default ShowAnnouncement;

